// Set Animations //

@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeDown {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

// Classes //

.fade-up {
  opacity: 0;
  animation: fadeUp 0.7s ease-in both;
}
.fade-down {
  opacity: 0;
  animation: fadeDown 0.7s ease-in both;
}

// Fade Ins //

.fade-in-1 {
  opacity: 0;
  animation: fadeIn 0.5s ease-in both;
  animation-delay: 0.1s;
}
.fade-in-2 {
  opacity: 0;
  animation: fadeIn 0.5s ease-in both;
  animation-delay: 0.2s;
}
.fade-in-3 {
  opacity: 0;
  animation: fadeIn 0.5s ease-in both;
  animation-delay: 0.3s;
}
.fade-in-4 {
  opacity: 0;
  animation: fadeIn 0.5s ease-in both;
  animation-delay: 4s;
}
.fade-in-5 {
  opacity: 0;
  animation: fadeIn 0.5s ease-in both;
  animation-delay: 0.5s;
}

.fade-in-nav {
  opacity: 0;
  animation: fadeIn 0.3s ease-in both;
}

// Fade Outs //

.fade-out-1 {
  opacity: 0;
  animation: fadeOut 0.5s ease-in both;
  animation-delay: 0.1s;
}
.fade-out-2 {
  opacity: 0;
  animation: fadeOut 0.5s ease-in both;
  animation-delay: 0.2s;
}
.fade-out-3 {
  opacity: 0;
  animation: fadeOut 0.5s ease-in both;
  animation-delay: 0.3s;
}
.fade-out-4 {
  opacity: 0;
  animation: fadeOut 0.5s ease-in both;
  animation-delay: 4s;
}
.fade-out-5 {
  opacity: 0;
  animation: fadeOut 0.5s ease-in both;
  animation-delay: 0.5s;
}

.fade-out-nav {
  opacity: 0;
  animation: fadeOut 0.3s ease-in both;
}
