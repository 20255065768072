// Import your CSS/SCSS files here:

body {
  font-family: poppins, sans-serif;
  @apply text-text bg-white;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: auto;
  -webkit-text-size-adjust: 100%;
  @media (max-width: 600px) {
    font-size: 15px;
  }
}

.wrapper {
  padding-left: 280px !important;
  padding-right: 280px !important;
  max-width: 1680px !important;
  margin: auto !important;
  @media (max-width: 1536px) {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  @media (max-width: 1280px) {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  @media (max-width: 768px) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

h1,
.h1 {
  font-size: responsive 35px 46px;
  letter-spacing: -0.035em;
  font-weight: 400;
  line-height: 1.1em;
  @apply text-grey;
}

h2,
.h2 {
  font-size: 46px;
  font-weight: 500;
  line-height: 46px;
  @media (max-width: 800px) {
    font-size: responsive 25px 30px;
    line-height: responsive 30px 40px;
  }
}
.paragraph {
  p {
    margin-bottom: 20px;
    @media (max-width: 1024px) {
      margin-bottom: 10px;
    }
  }
}
p {
  font-weight: 400;
}
.font-light {
  h2,
  .h2 {
    font-weight: 300;
    font-size: 40px;
    @media (max-width: 800px) {
      font-size: responsive 28px 30px;
      line-height: responive 31px 40px;
    }
    strong {
      font-weight: 400;
      &:nth-of-type(1) {
        @apply text-lime;
      }
      &:nth-of-type(2) {
        @apply text-pink;
      }
      &:nth-of-type(3) {
        @apply text-yellow;
      }
    }
  }
}
// .home-title {
//   h2 {
//     font-size: responsive 28px 30px;
//     line-height: responive 33px 40px;
//     font-weight: 300;
//   }
// }
h3,
.h3 {
  font-size: 22px;
  font-weight: 500;
}

h4,
.h4 {
  font-size: 15px;
}

h5,
.h5 {
  font-size: 13px;
}

h6,
.h6 {
  font-size: 13px;
}

p,
.p,
a,
.a {
  line-height: responsive 20px 26px;
  font-size: rsponsive 14px 17px;
}

header {
  p,
  .p,
  a,
  .a {
    line-height: 22px;
    font-size: 17px;
  }
}

.sub-title {
  letter-spacing: 1.75px;
}
.pink-glow {
  box-shadow: 1px 0px 15px 0px rgba(250, 5, 111, 0.45);
  -webkit-box-shadow: 1px 0px 15px 0px rgba(250, 5, 111, 0.45);
  -moz-box-shadow: 1px 0px 15px 0px rgba(250, 5, 111, 0.45);
}
.lime-glow {
  box-shadow: 1px 0px 15px 0px rgba(129, 251, 207, 0.45);
  -webkit-box-shadow: 1px 0px 15px 0px rgba(129, 251, 207, 0.45);
  -moz-box-shadow: 1px 0px 15px 0px rgba(129, 251, 207, 0.45);
}
.yellow-glow {
  box-shadow: 1px 0px 15px 0px rgba(236, 247, 0, 0.45);
  -webkit-box-shadow: 1px 0px 15px 0px rgba(236, 247, 0, 0.45);
  -moz-box-shadow: 1px 0px 15px 0px rgba(236, 247, 0, 0.45);
}
.border-yellow {
  --tw-border-opacity: 1 !important;
  border-color: rgba(236, 247, 0, var(--tw-border-opacity)) !important;
}
.border-lime {
  --tw-border-opacity: 1 !important;
  border-color: rgba(129, 251, 207, var(--tw-border-opacity)) !important;
}
.border-pink {
  --tw-border-opacity: 1 !important;
  border-color: rgba(250, 5, 111, var(--tw-border-opacity)) !important;
}
.bg-yellow {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(236, 247, 0, var(--tw-bg-opacity)) !important;
}
.service-overview {
  p {
    font-size: 16px;
    margin-bottom: 1rem;
    strong {
      font-size: 30px;
      line-height: 40px;
      font-weight: 300;
      @media (max-width: 700px) {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
}
.digital-marketing-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-column-gap: 80px;
  grid-row-gap: 0px;
  @media (max-width: 900px) {
    display: block;
  }
}
.creative-design-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 80px;
  grid-row-gap: 0px;
  @media (max-width: 900px) {
    display: block;
  }
}
.web-development-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 80px;
  grid-row-gap: 0px;
  @media (max-width: 900px) {
    display: block;
  }
}

.div1 {
  grid-area: 1 / 1 / 3 / 2;
}
.div2 {
  grid-area: 2 / 2 / 4 / 3;
}
.div3 {
  grid-area: 3 / 1 / 5 / 2;
}
.div4 {
  grid-area: 4 / 2 / 6 / 3;
}
.div5 {
  grid-area: 5 / 1 / 7 / 2;
}
.div6 {
  grid-area: 6 / 2 / 8 / 3;
}
.div7 {
  grid-area: 7 / 1 / 9 / 2;
}
.div8 {
  grid-area: 8 / 2 / 10 / 3;
}
.div9 {
  grid-area: 9 / 1 / 11 / 2;
}
.div10 {
  grid-area: 10 / 2 / 12 / 3;
}
.div11 {
  grid-area: 11 / 1 / 13 / 2;
}
.text-block {
  ul {
    list-style-type: circle !important;
    margin-left: 30px;
    margin-top: 10px;
    margin-bottom: 20px;
    li {
      margin-bottom: 15px;
      font-size: 16px;
    }
  }
  .p,
  p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 1em;
  }
  h1,
  .h1 {
    font-size: 38px;
    color: white;
    @apply mb-8 font-bold;
  }
  h2,
  .h2 {
    font-size: 28px;
    @apply mb-8 font-bold;
  }

  h3,
  .h3 {
    font-size: 22px;
    @apply font-bold;
  }

  h4,
  .h4 {
    font-size: 15px;
  }

  h5,
  .h5 {
    font-size: 13px;
  }

  h6,
  .h6 {
    font-size: 13px;
  }
}
.news-wrapper {
  margin: 0 auto;
  max-width: calc(1160px + 20vw);
  padding: 0 10vw;
  width: 100%;
}
.news-block h3 {
  margin-bottom: 2rem;
}
.plyr--video {
  border-radius: 1.5rem !important;
}
.mega-nav {
  opacity: 0;
  visibility: hidden;
  display: none;
  .wrapper {
    margin: 0 auto;
    max-width: calc(1160px + 20vw);
    padding: 0 10vw;
    width: 100%;
  }
}
.max-cont {
  max-width: 1180px;
  margin: 0 auto;
  padding: 0 2rem;
}
.mega-nav.open {
  opacity: 1;
  visibility: visible;
  display: block;
}
.nav-text {
  font-size: responsive 30px 60px;
  line-height: responsive 40px 80px;
  color: white;
  @media (max-width: 1100px) {
    font-size: 50px;
  }
  @media (max-width: 800px) {
    font-size: 30px;
  }
}
.nav-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  @media (max-width: 1024px) {
    display: block;
  }
  .div1 {
    grid-area: 1 / 1 / 4 / 2;
  }
  .div2 {
    grid-area: 1 / 2 / 4 / 3;
  }
  .div3 {
    grid-area: 1 / 3 / 4 / 4;
  }
  .div4 {
    grid-area: 4 / 1 / 5 / 4;
    .service-link {
      position: relative;
      color: white;
      span {
        position: relative;
        z-index: 10;
      }
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 0;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
      }
    }
    .service-link:hover {
      &::before {
        height: 100%;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
      }
    }
    .border-lime {
      &::before {
        @apply bg-lime;
      }
    }
    .border-pink {
      &::before {
        @apply bg-pink;
      }
    }
    .border-yellow {
      &::before {
        @apply bg-yellow;
      }
    }
  }
}
.project-desc {
  p {
    margin-bottom: 1rem;
  }
}
.boxed-btn {
  input[type="checkbox"] {
    display: none;
  }

  label {
    display: inline-block;
    border: 1px solid grey;
    cursor: pointer;
  }

  .blank-label {
    display: none;
  }

  label {
    display: inline-block;
    border: 1px solid white;
    cursor: pointer;
    background: transparent;
  }

  label:hover {
    display: inline-block;
    border: 1px solid grey;
    cursor: pointer;
    background: #8eba3d;
  }

  input[type="checkbox"]:checked + label {
    background: #81fbcf;
    color: #3c3c3b !important;
  }
}

.pink-bold {
  strong {
    color: #fa056f !important;
  }
}

.lime-bold {
  strong {
    color: #81fbcf !important;
  }
}
.yellow-bold {
  strong {
    color: #ecf700 !important;
  }
}

.ipop-logo {
  filter: drop-shadow(0px 0px 13px #fa056f);
}

.hover-glow-pink:hover {
  filter: drop-shadow(0px 0px 13px #fa056f);
}

.hover-glow-lime:hover {
  filter: drop-shadow(0px 0px 13px #81fbcf);
}

.hover-glow-yellow:hover {
  filter: drop-shadow(0px 0px 13px #ecf700);
}

.hover-glow-white:hover {
  filter: drop-shadow(0px 0px 13px #fff);
}

/* flickity-fade */

.flickity-enabled.is-fade .flickity-slider > * {
  pointer-events: none;
  z-index: 0;
}

.flickity-enabled.is-fade .flickity-slider > .is-selected {
  pointer-events: auto;
  z-index: 1;
}

.text-repeater {
  p {
    margin: 0;
  }
}

.header-heights {
  height: 100vh !important;
  min-height: 590px !important;
  @media (max-width: 1536px) {
    min-height: 815px;
    max-height: 815px;
  }
  @media (max-width: 1024px) {
    min-height: 815px;
    max-height: 815px;
  }
}
.video-position {
  left: 50%;
  transform: translateX(-50%);
}

@keyframes letter {
  from {
    opacity: 0;
    // transform: translateY(100px) scale(0.8);
    transform: translateY(1em) scale(0.8);
  }
  to {
    opacity: 1;
    transform: none;
    filter: none;
  }
}

.home-intro__logo {
  position: relative;
  top: -0.1em;
}

[data-action="TextAnim"] {
  opacity: 0;
}

$easing: cubic-bezier(0, 1, 0.45, 1); // my custom
// $easing: cubic-bezier(0.55, 0, 0.1, 1); // swift out
// $easing: cubic-bezier(0, 0.55, 0.45, 1); // circ out

.ta-letter {
  opacity: 0;
  animation: letter 2s $easing forwards;
  top: -0.1em;
  position: relative;
}

.ta-word {
  overflow: hidden;
  display: inline-flex;
  margin-right: 0.15em;
  position: relative;
  padding-top: 5px;
}

@keyframes ta-color {
  from {
    color: currentColor;
  }
  to {
    @apply text-pink;
  }
}

.ta-color {
  animation: ta-color 1s ease-out forwards;
  animation-delay: 2s;
}
.star {
  width: 100%;
  height: 100%;
  position: relative;
  .background-grid {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    background-image: url("/assets/gridstar.png");
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 4rem 0;
  }
  .grey {
    fill: #dedede;
  }

  .green {
    fill: #81f3c3;
  }
  .grid-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .grid-container svg {
    max-width: 100%;
    max-height: 100%;
  }
}
.service-icon-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}
.service-icon-wrapper .background-grid {
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 4rem 0;
}
.service-icon-wrapper .grey {
  fill: #dedede;
}
.service-icon-wrapper .green {
  fill: #81f3c3;
}
.service-icon-wrapper .yellow {
  fill: #ecf000;
}
.service-icon-wrapper .pink {
  fill: #fa0b69;
}
.service-icon-wrapper .grid-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.service-icon-wrapper .grid-container svg {
  max-width: 100%;
  max-height: 100%;
}
.order-2 {
  -webkit-box-ordinal-group: 3 !important;
  -ms-flex-order: 2 !important;
  order: 2 !important;
}
.landing {
  input,
  textarea {
    border-color: #3c3c3b;
  }
}
.land-heading {
  strong {
    color: #ecf000;
  }
}
.stats-cont{
  @media (min-width: 1000px) {
  position: relative;
  &::before{
    content: '';
    position: absolute;
    left: 0;
    top:0;
    width: 1px;
    height: 50px;
    background-color: white;
  }
  &::after{
    content: '';
    position: absolute;
    left: 0;
    bottom:0;
    width: 1px;
    height: 50px;
    background-color: white;
  }
}
}
.stats-holder{
  position: relative;
  @media (min-width: 1000px) {
  &::before{
    content: '';
    position: absolute;
    right: 0;
    top:0;
    width: 1px;
    height: 50px;
    background-color: white;
  }
  &::after{
    content: '';
    position: absolute;
    right: 0;
    bottom:0;
    width: 1px;
    height: 50px;
    background-color: white;
  }
}
}

.accordion-header-color {
  color: #81F3C2;
}

.accordion-header-index {
  font-size: 16px;
  color: white;
}

div.pb-5.px-5.accordion-content.border-top > p {
  color: white;
  font-size: 16px;
  padding-left: 2.25rem;
  padding-right: 2.25rem;
  max-width: 900px;
}

#accordion-open .accordion-content {
  overflow: hidden;
}





